import {
  useAdminLoginMutation,
  useAdminLogoutMutation,
} from "generated/graphql";
import { createContext, ReactNode, useEffect, useReducer } from "react";
// utils
import { getRefreshToken, isValidToken, setSession } from "../utils/jwt2";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
    };
  },
  REFRESH_TOKEN: (state, action) => {
    const { isAuthenticated } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

interface IAuthContext {
  isAuthenticated: boolean;
  isInitialized: boolean;
  method: string;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  postLogin: (res: any) => void;
}
const AuthContext = createContext<IAuthContext>({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  postLogin: () => {},
});

// ----------------------------------------------------------------------

interface AuthProviderProps {
  children: ReactNode;
}
function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [onLogin] = useAdminLoginMutation();
  const [logoutServer] = useAdminLogoutMutation();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem("adminAccessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
            },
          });
        } else {
          const success = await getRefreshToken();
          if (success) {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
              },
            });
          } else {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: false,
              },
            });
          }
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (account, password) => {
    try {
      const response = await onLogin({
        variables: { adminLoginInput: { account, password } },
      });
      if (response?.data?.adminLogin.success) {
        const {
          adminLogin: { accessToken, user },
        } = response.data;

        setSession(accessToken);

        dispatch({
          type: "LOGIN",
          payload: {
            user,
          },
        });
      } else {
        if (response?.data?.adminLogin.message)
          throw response.data.adminLogin.message;
      }
    } catch (e) {
      throw e || "Server error!";
    }
  };
  const postLogin = (response) => {
    try {
      if (response?.success) {
        const { accessToken, user } = response;

        setSession(accessToken);

        dispatch({
          type: "LOGIN",
          payload: {
            user,
          },
        });
      } else {
        if (response?.message) throw response?.message;
      }
    } catch (e) {
      throw e || "Server error!";
    }
  };

  const logout = async () => {
    setSession(null);
    console.log(state);
    await logoutServer({
      variables: { userId: state?.user?.id },
    });
    dispatch({ type: "LOGOUT" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        postLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
