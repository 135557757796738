import { Box, Stack, Typography } from "@mui/material";
// @mui
import { styled } from "@mui/material/styles";
import BadgeStatus from "components/BadgeStatus";
// components
import MyAvatar from "../../../components/MyAvatar";
// hooks
// routes

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

interface INavbarAccount {
  isCollapse?: boolean;
}
export default function NavbarAccount({ isCollapse }: INavbarAccount) {
  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          bgcolor: "transparent",
        }),
      }}
    >
      <MyAvatar />

      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create("width", {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        <Typography variant="subtitle2" noWrap>
          Admin
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <BadgeStatus size="large" status="online" />
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Online
          </Typography>
        </Stack>
      </Box>
    </RootStyle>
  );
}
