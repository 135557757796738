// routes

import SvgIconStyle from "components/SvgIconStyle";
import { PATH_DASHBOARD } from "Router/paths";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dashboard: getIcon("ic_kanban"),
  blog: getIcon("ic_blog"),
  people: getIcon("ic_user"),
  chat: getIcon("ic_chat"),
  club: getIcon("ic_menu_item"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: "menu.users",
        path: PATH_DASHBOARD.user,
        icon: ICONS.people,
      },
    ],
  },
];

export default navConfig;
