// hooks
// utils
import createAvatar from "../utils/createAvatar";
//
import Avatar from "./Avatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const userName = "Admin" || "";
  return (
    <Avatar alt={"userAvatar"} color={createAvatar(userName).color} {...other}>
      {createAvatar(userName).name}
    </Avatar>
  );
}
